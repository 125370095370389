import React from 'react'

const ShowMoreCurves = ({className}) => {
  return (
    <svg className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 794 794"
  >
    <path
      d="M1871.73 790.12c0-95.337-83.51-172.738-186.38-172.738H337.767c-102.866 0-186.381 77.401-186.381 172.738 0 95.336 83.515 172.737 186.381 172.737H1685.35c102.87 0 186.38-77.401 186.38-172.737Z"
      style={{
        fill: "#e5007d",
      }}
      transform="matrix(.20956 .20986 -.22644 .2261 253.236 -135.292)"
    />
    <path
      d="M1871.73 790.12c0-95.337-83.51-172.738-186.38-172.738H337.767c-102.866 0-186.381 77.401-186.381 172.738 0 95.336 83.515 172.737 186.381 172.737H1685.35c102.87 0 186.38-77.401 186.38-172.737Z"
      style={{
        fill: "#e5007d",
      }}
      transform="matrix(.20973 -.2097 .22626 .2263 -104.71 571.485)"
    />
    <path
      d="M1871.73 790.12c0-95.337-83.51-172.738-186.38-172.738H337.767c-102.866 0-186.381 77.401-186.381 172.738 0 95.336 83.515 172.737 186.381 172.737H1685.35c102.87 0 186.38-77.401 186.38-172.737Z"
      style={{
        fill: "#e5007d",
      }}
      transform="matrix(.20956 .20986 -.22644 .2261 481.832 -135.292)"
    />
    <path
      d="M1871.73 790.12c0-95.337-83.51-172.738-186.38-172.738H337.767c-102.866 0-186.381 77.401-186.381 172.738 0 95.336 83.515 172.737 186.381 172.737H1685.35c102.87 0 186.38-77.401 186.38-172.737Z"
      style={{
        fill: "#e5007d",
      }}
      transform="matrix(.20973 -.2097 .22626 .2263 123.886 571.485)"
    />
  </svg>
  )
}

export default ShowMoreCurves